<template>
    <div class="package-item package-item--additional">
        <h3 class="package-title">{{ name }}</h3>
        <ul class="package-item__ul">
            <li class="package-info">
                <p class="feature-desc" v-html="description"></p>
                <p class="feature-name" >
                     {{ duration }}
                </p>
            </li>
        </ul>
        <footer class="package-item__footer">
            <p class="price price--additional">{{ price.value }} <span class="currency">{{ price.label }}</span></p>
            <button v-if="!recurring || (!active && recurring)" class="red-button"
                    @click="openModal(id)"
            >
                {{ $t('buttons.activate') }}
            </button>

            <button v-if="active && recurring" class="red-button"
                    @click="openDeactivateModal(id)"
            >
                {{ $t('buttons.deactivate') }}
            </button>            
        </footer>
    </div>
</template>

<script>
import Package from './Package';
export default Package;
</script>
